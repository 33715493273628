import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from 'src/contexts/AuthProvider';
import { PATH_APP } from 'src/paths';

export function GuestGuard({ children }: { children: ReactNode }) {
  const { push } = useRouter();

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      push(PATH_APP.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return children;
}
