import { ReactNode } from 'react';
import { FormProvider as ReactHookFormProvider, UseFormReturn } from 'react-hook-form';

interface Props {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  onBlur?: VoidFunction;
}

export function FormProvider({ children, methods, onSubmit, onBlur }: Props) {
  return (
    <ReactHookFormProvider {...methods}>
      <form onSubmit={onSubmit} onBlur={onBlur}>
        {children}
      </form>
    </ReactHookFormProvider>
  );
}
