import { useState } from 'react';
import { TextField, TextFieldProps, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface TooltipVariantProps {
  hasError?: boolean;
}

export const SprInputTooltip = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ className, hasError, ...tooltipProps }: TooltipProps & TooltipVariantProps) => (
    <Tooltip {...tooltipProps} classes={{ popper: className }} />
  ),
)<TooltipVariantProps>(({ theme, hasError }) => ({
  zIndex: theme.zIndex.modal - 1, // so datepicker overlay the error tooltips on inputs

  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 240,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${hasError ? theme.palette.error.main : theme.palette.grey['400']}`,
    backgroundColor: hasError ? theme.palette.error.lighter : theme.palette.grey['200'],
    color: hasError ? theme.palette.error.darker : theme.palette.text.primary,
    letterSpacing: '0.2px',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: hasError ? theme.palette.error.lighter : theme.palette.grey['200'],
    '&::before': {
      border: `1px solid ${hasError ? theme.palette.error.main : theme.palette.grey['400']}`,
    },
  },

  [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}.${tooltipClasses.tooltip}`]: {
    marginBottom: -1,
  },

  [`&[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}.${tooltipClasses.tooltip}`]: {
    marginTop: -1,
  },
}));

export interface SprInputTooltipProps {
  tooltipVisibility?: 'always' | 'onFocus' | 'onError';
  tooltipProps?: TooltipProps;
}

export type SprInputProps = SprInputTooltipProps & TextFieldProps;

export function SprInput({
  tooltipVisibility = 'onFocus',
  error,
  helperText,
  onFocus,
  onBlur,
  tooltipProps,
  ...textFieldProps
}: SprInputProps) {
  const [hasFocus, setHasFocus] = useState(false);

  const isVisible = error || tooltipVisibility === 'always' || (tooltipVisibility === 'onFocus' && hasFocus);

  const localOnFocus: TextFieldProps['onFocus'] = (event) => {
    setHasFocus(true);
    onFocus?.(event);
  };
  const localOnBlur: TextFieldProps['onBlur'] = (event) => {
    setHasFocus(false);
    onBlur?.(event);
  };

  return (
    <SprInputTooltip arrow open={isVisible} title={helperText} hasError={error} {...tooltipProps}>
      {/* span is needed to capture the tooltip ref */}
      <span>
        <TextField error={error} onFocus={localOnFocus} onBlur={localOnBlur} {...textFieldProps} />
      </span>
    </SprInputTooltip>
  );
}
