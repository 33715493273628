import Head from 'next/head';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Logo } from 'src/components/Logo';
import { GuestGuard } from 'src/guards/GuestGuard';
import { LoginForm } from 'src/sections/auth/LoginForm';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  return (
    <GuestGuard>
      <Head>
        <title>Login | SponsoRights</title>
      </Head>
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Box mb={1}>
              <Typography variant="h4" gutterBottom>
                Melden Sie sich bei SponsoRights an
              </Typography>
            </Box>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </GuestGuard>
  );
}
