import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

interface RHFSelectProps {
  name: string;
  children: any;
  fullWidth?: boolean;
}

export function RHFSelect({ name, children, fullWidth, ...other }: RHFSelectProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, ...otherFieldProps }, fieldState: { error } }) => (
        <TextField
          {...other}
          inputRef={ref}
          value={value ?? ''}
          {...otherFieldProps}
          select
          fullWidth={fullWidth != null ? fullWidth : true}
          error={!!error}
          helperText={error ? error.message : other.helperText}
        >
          {children}
        </TextField>
      )}
    />
  );
}
