import { ChangeEvent } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { SprInput, SprInputProps } from './SprInput';

interface IProps {
  name: string;
  rules?: RegisterOptions;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

type Props = IProps & SprInputProps;

export function RHFTextField({ name, rules, onChange, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, value, ...otherFieldProps }, fieldState: { error } }) => (
        <SprInput
          {...otherFieldProps}
          fullWidth
          value={typeof value === 'number' && value === 0 ? '' : value ?? ''} // needed to prevent changing from an uncontrolled input to a controlled one
          onChange={(e) => {
            otherFieldProps.onChange(e);
            if (onChange) onChange(e);
          }}
          inputRef={ref}
          error={!!error}
          helperText={error?.message ?? helperText}
          {...other}
        />
      )}
    />
  );
}
